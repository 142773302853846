.ModelPage_Spotlight .add_new_data{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.ModelPage_Spotlight .list_contents{
    display: flex;
    flex-direction: column;
}
.ModelPage_Spotlight .list_data_input{
    display: flex;
    flex-direction: row;
    gap: 16px;
}